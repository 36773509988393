import React from 'react';
import { useHistory } from 'react-router';
import { lazyload } from '@cloudinary/react';
import { TextVariant } from '@naf/text';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from '../../hooks/useCloudinary';
import { BenefitsType } from '../../../../types/benefitsType';
import { MembershipBenefitReference } from '../../../../types/membershipBenefitReferenceType';
import defaultBenefitImage from '../../../assets/images/defaultLocalBenefit.svg?url';
import * as S from './Styles';
import { formatDate } from '../../utils/Dates';

type BenefitCardProps = {
  benefit?: BenefitsType | MembershipBenefitReference;
  title?: string;
  name?: string;
  img?: {
    src: string;
    alt: string;
  };
  isFlexible?: boolean;
};

const BenefitCard = ({
  benefit: {
    image,
    title,
    name,
    listIngress,
    slug,
    campaignDetails,
    partner,
    id,
    discountBadge,
    isLocalContent,
    abTestExperimentSlug,
  },
  img,
  isFlexible = false,
}: BenefitCardProps) => {
  const itemSlug = slug || id;
  const history = useHistory();
  const cld = useCloudinary();
  const cldImage =
    image &&
    image.publicId &&
    cld.image(image.publicId).resize(fill().width(768).height(432)).quality('auto:eco').format('auto');
  const handleLocalPath = () => {
    if (history.location.pathname.includes('/fordeler'))
      return history.location.pathname.replace(/\/[^/]*$/, `/${slug}`);
    return `${history.location.pathname}/fordeler/${slug}`;
  };

  // Link to document
  const documentLink = isLocalContent ? handleLocalPath() : `/medlemskap/medlemsfordeler/${itemSlug}`;

  // Check whether the documentLink is overwritten by the document being part of an experiment
  // If it is, route the link via the experiment it is part of.
  const link = abTestExperimentSlug ? `/${abTestExperimentSlug}` : documentLink;
  const hasActiveCampaign = campaignDetails?.endDate && new Date(campaignDetails.endDate) > new Date();
  const formattedDate = campaignDetails?.endDate ? formatDate(campaignDetails.endDate) : null;

  return (
    <S.Wrapper to={link} $isFlexible={isFlexible}>
      {!!hasActiveCampaign && <S.Campaign variant="moss" text={`Varer til ${formattedDate}`} />}
      <S.Header $isFlexible={isFlexible}>
        {cldImage && !img && (
          <S.StyledAdvancedImage
            alt={image?.altText || ''}
            cldImg={cldImage || defaultBenefitImage}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
          />
        )}
        {img && !cldImage && <img src={img.src} alt={img.alt} draggable="false" />}
      </S.Header>
      {partner?.logo ? (
        <S.LogoWrapper>
          {partner?.logo && <S.PartnerLogo src={partner.logo.srcSet.jpgLarge} alt="Partnerlogo" />}
        </S.LogoWrapper>
      ) : (
        <S.LogoWrapper>
          <S.LogoPlaceholder /> {/* Placeholder to maintain spacing if no logo is provided. */}
        </S.LogoWrapper>
      )}
      <S.Description>
        <S.Title variant={TextVariant.Header3}>{title || name}</S.Title>
        <S.Ingress variant={TextVariant.ArticleText}>{listIngress}</S.Ingress>
      </S.Description>
      {discountBadge && <S.Discount variant={TextVariant.Header2}>{discountBadge}</S.Discount>}
    </S.Wrapper>
  );
};

export default BenefitCard;
